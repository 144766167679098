import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { GetRecordingInsightsQuery } from '@app/features/summaries/components/summaries/get-recordings.onelife.generated';
import { summaryIsUnsignedStartedAppointment } from '@app/features/summaries/shared/summaries-utils';
import { Summary } from '@app/features/summaries/shared/summaries.type';
import { RecordingManagerComponent } from '../recording/recording-manager/recording-manager.component';
import { HealthscribeBannerVariant } from './v2/healthscribe-banner-v2.component';

@Component({
  selector: 'omg-healthscribe-banner-container',
  templateUrl: './healthscribe-banner-container.component.html',
})
export class HealthscribeBannerContainerComponent implements OnChanges {
  @Input() summary: Summary;
  @Input()
  appointmentRecording: GetRecordingInsightsQuery['appointmentRecording'];
  @Input()
  appointmentRecordingIsLoading: boolean;

  @Output() viewSummary = new EventEmitter<Event>();

  @ViewChild(RecordingManagerComponent)
  recordingManager: RecordingManagerComponent;

  private healthscribeFlag: boolean | undefined;
  private healthscribeInChartFlag: boolean | undefined;
  bannerState = signal<
    'transcriptAvailable' | 'recording' | 'dismissing' | 'dismissed' | 'default'
  >('default');

  dismissTimeout: NodeJS.Timeout;
  constructor(
    private launchDarkly: LaunchDarklyService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnChanges(): void {
    if (this.appointmentRecording?.state === 'transcribed') {
      this.bannerState.set('transcriptAvailable');
    }
  }

  showHealthscribeBanner(summary: Summary): 'none' | 'v1' | 'v2' {
    this.healthscribeFlag ??= this.launchDarkly.variation<boolean>(
      FeatureFlagNames.healthscribeBannerVersionOne,
      false,
    );
    this.healthscribeInChartFlag ??= this.launchDarkly.variation<boolean>(
      FeatureFlagNames.healthscribeInChart,
      false,
    );

    // Only show if the summary hasn't been signed yet and the appointment is in a started-adjacent state
    const shouldShow = summaryIsUnsignedStartedAppointment(summary);

    if (this.healthscribeFlag && shouldShow) {
      return 'v1';
    } else if (
      this.healthscribeInChartFlag &&
      this.bannerState() !== 'dismissed' /* && shouldShow */
    ) {
      return 'v2';
    }
    return 'none';
  }

  get bannerVariant(): HealthscribeBannerVariant {
    if (this.bannerState() === 'dismissing') {
      return 'dismissing';
    } else if (this.bannerState() === 'transcriptAvailable') {
      return 'success';
    } else return 'instructional';
  }

  dismissHealthScribe(): void {
    this.bannerState.set('dismissing');
    this.dismissTimeout = setTimeout(() => {
      this.bannerState.set('dismissed');
    }, 5000);
  }
  undoDismissHealthScribe(): void {
    if (this.dismissTimeout) {
      clearTimeout(this.dismissTimeout);
    }
    this.bannerState.set('default');
  }

  startHealthScribeRecording(): void {
    this.bannerState.set('recording');
    // the recording manager is hidden behind an ngIf, so will not be available as a viewChild until detectChanges is called.
    this.changeDetector.detectChanges();
    this.recordingManager.record();
  }
}
